
body {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  background: #fafafa;
}

p {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  padding-left: 20px;
  min-width: 250px;
  max-width: 250px;
  min-height: 100vh;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar {
    color: #fff;
    transition: all 0.3s;
}

#sidebar .sidebar-header {
  padding-top: 22px;
  padding-left: 14px;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    color: #90a4ae;
    font-size: 17.6px;
}

#sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
}

#sidebar h3 {
  color: #90a4ae;
}

.logo h3 {
  color: #90a4ae;
}

.logo span {
  color: #90a4ae;
  font-size: 0.5em;
}

#content {
  width: 75%;
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0 20px 0;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

.bg-dark {
  background-color: #263238 !important;
}

.sidebar-byline {
  color: #90a4ae;
  font-variant: small-caps;
  font-weight: 900;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
  min-height: 30px;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

.pointer {
  cursor: pointer;
}

.label-cell {
  padding-left: 12px;
}

.photo-cell {
  padding: 5px 12px;
  cursor: pointer;
  text-align: left;
  font-size: 1.5em;
}

.quotes-table {
  width: 99%;
}

.loginContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.loginRow {
  height: 100%;
}

.loginImage {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 250px !important;
}

.loginButton {
  position: fixed;
  top: 58%;
  left: 53%;
}

.ant-layout .logo {
  width: 400px;
  height: 31px;
  /* background: rgba(255, 255, 255, 0.2); */
  margin: 16px 24px 16px 0;
  float: left;
}

.wrapper .ant-layout {
  background: #fff ;
}

.logout {
  float: right;
}

.username {
  float: right;
}

.quotes {
  float: right;
}

.supplier-table {
  width: 40%;
}

.table-wrapper {
  width: 300%;
  padding-right:5%;
}

.table-wrapper-1 {
  width: 95%;
}

.table-wrapper-2 {
  width: 95%;
}

.table-wrapper-3 {
  width: 95%;
}

.table-wrapper-4 {
  width: 120%;
  min-width: 120%;
  padding-right:5%;
}

.table-wrapper-5 {
  width: 145%;
  min-width: 145%;
  padding-right:5%;
}

.table-wrapper-6 {
  width: 170%;
  min-width: 170%;
  padding-right:5%;
}

.table-wrapper-7 {
  width: 195%;
  min-width: 195%;
  padding-right:5%;
}

.table-wrapper-8 {
  width: 220%;
  min-width: 220%;
  padding-right:5%;
}

.table-wrapper-9 {
  width: 245%;
  min-width: 245%;
  padding-right:5%;
}

.table-wrapper-10 {
  width: 290%;
  min-width: 290%;
  padding-right:5%;
}

.supplier-table {
  width: 75%;
}

.users-table {
  width: 99%;
}

.ant-table-row {
  font-size: 1em;
  font-weight: 100;
}

.flexContainer {
  display: flex;
}

.flexColumn {
  flex-direction: column;
  flex: 45%;
}

div#addProductsTable table tr td:first-child{
  background-color: #fafafa;
  max-width: 15% !important;
  width: 15% !important;
}

h5 .content {
  font-weight: 100;
}

.short-input {
  width: 200px !important;
}

.validation-error-box {
  border: 2px solid red;
  background-color: pink;
  padding: 5px;
  width: 100px;
  font-weight: bold;
  color: black;
}

.validation-error-box .validation-reason {
  font-weight: normal !important;
}

.flexrow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding: 0px !important;
}

.flexcolumn {
  width:100%;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding-left:7px;
  margin-right:7px;
  padding-top:10px;
  padding-bottom:10px;
  border-radius: 0.2rem;
}

.flex0 {flex: 0}
.flex1 {flex: 1}
.flex2 {flex: 2}
.flex3 {flex: 3}
.flex4 {flex: 4}
.flex5 {flex: 5}
.flex6 {flex: 5}
.flex7 {flex: 7}
.flex8 {flex: 8}
.flex9 {flex: 9}
.flex10 {flex: 10}
.flex11 {flex: 11}
.flex12 {flex: 12}
.flex14 {flex: 13}
.flex10 {flex: 14}
.flex15 {flex: 15}
.flex20 {flex: 20}


.instructions {
  border: 2px solid orange;
  border-radius: 5px;
  background-color: lightyellow;
}

.step {
  font-weight: bold;
}






/* final overwrites - leave at the bottom of file */

.ant-table {
  color: black !important;
}

